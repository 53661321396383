import React, { Component } from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import {
  Typography,
  Grid,
  Card,
  Button,
  InputAdornment,
  Hidden,
  CircularProgress,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  OutlinedTextFiled,
  MobileOutlinedTextFiled,
  ConnectionOutlinedTextFiled,
} from "../auth/auth-style";
import ReactCountryFlag from "react-country-flag";
import { AboutAccount } from "./about-your-account";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import { usageService } from "../../services/index";
import { Skeleton, Alert } from "@material-ui/lab";
import { store } from "../../helpers";
import { appActions } from "../../actions/app.actions";
import { connect } from "react-redux";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import ConnectionFinder from "./quick-pay-connection-finder"

let labels = "";
let mobileLength = "";
let country_Calling_Code = "";
let country_Code = "";
let connectionId_length = "";
let email_regex = "";
let billId_length = "";
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    country_Calling_Code = store.getState().parametersWidgets.ParameterLookup
      .COUNTRY_DIALING_CODE;
    country_Code = store.getState().parametersWidgets.ParameterLookup
      .COUNTRY_CODE;
    mobileLength = store.getState().parametersWidgets.ParameterLookup
      .MOBILE_LENGTH;
    mobileLength = Number(mobileLength);
    connectionId_length = store.getState().parametersWidgets.ParameterLookup
      .CONNECTION_ID_LENGTH;
    connectionId_length = Number(connectionId_length);
    email_regex = store.getState().parametersWidgets.ParameterLookup
      .EMAIL_REGEX;
    billId_length = store.getState().parametersWidgets.ParameterLookup
      .BILLID_LENGTH;
    billId_length = Number(billId_length);
  }
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.quaternary.main,
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class QuickPayLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billId: props.billId,
      connectionId: props.billId ? props.billId : ((document.cookie.match(new RegExp('(^| )' + 'connectionId' + '=([^;]+)')))?.[2]),
      mobileNumber: (document.cookie.match(new RegExp('(^| )' + 'mobileNumber' + '=([^;]+)')))?.[2],
      emailId: (document.cookie.match(new RegExp('(^| )' + 'emailId' + '=([^;]+)')))?.[2],
      emailError: false,
      mobileError: false,
      connectionIdError: false,
      openDialog: false,
      error: false,
      msg: "",
      isSubmitLoading: false,
      directRedirection: false,
      countryCode: "+974",
    };

    const rocketContainer = document.querySelector(".rocketchat-container");
    const rocketWidget = document.querySelector(".rocketchat-widget");
    if (rocketContainer && rocketWidget) {
      rocketContainer.style.display = "none";
      rocketWidget.style.display = "none";
    }

    this.handleChange = this.handleChange.bind(this);
    this.connectionIdBlur = this.connectionIdBlur.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlesubmitClick = this.handlesubmitClick.bind(this);
    this.billingAPICall = this.billingAPICall.bind(this);
    this.handleOnChangePhone = this.handleOnChangePhone.bind(this);
  }

  componentDidMount() {
    if(document.getElementById("mobileNumber_Id")){
    document.getElementById("mobileNumber_Id").style.padding = "9px 1vw";
    document.getElementById("mobileNumber_Id").style.borderRadius = "4px";
    document.getElementById("mobileNumber_Id").style.borderColor = "lightgrey";
    document.getElementById("mobileNumber_Id").style.borderWidth = "thin";
    }
    this.emailBlur = this.emailBlur.bind(this);
    if(this.state.mobileNumber){
      const {
        emailId,
        connectionId,
        mobileNumber,
      } = this.state;
    }
    if (this.state.billId) {
      const {
        emailId,
        connectionId,
        connectionIdError,
        mobileError,
        emailError,
        mobileNumber,
      } = this.state;
      let notGo = "false";
      this.connectionIdBlur();
      if (connectionId) {
        if (!connectionIdError) {
          if (this.props.billpay) {
            //billpay APi
            this.billingAPICall(this.state.connectionId);
          } else {
            if (mobileNumber || emailId) {
              if (mobileNumber) {
                this.setState({
                  error: false,
                }); 
                
                this.setState({
                  mobileError: false,
                });
              }
              if (emailId) {
                this.setState({
                  error: false,
                });

                var emailRegEx = new RegExp(email_regex);
                var test = emailRegEx.test(emailId);
                if (test) {
                  this.setState({ emailError: false });
                } else {
                  notGo = "true";
                  this.setState({ emailError: true });
                }
              }
              if (notGo !== "true") {
                this.setState({
                  isSubmitLoading: true,
                });
                localStorage.setItem("billpay", false);
                usageService.getBalance(this.state.connectionId).then(
                  (res) => {
                    if(res.data.postBalanceDetails){
                    localStorage.setItem("landing",JSON.stringify(res.data.postBalanceDetails));
                    localStorage.setItem("email",emailId);
                    localStorage.setItem("mobileNo",mobileNumber)
                    this.props.getConnectionDetails(
                      res.data.postBalanceDetails,
                      mobileNumber,
                      emailId
                    );
                    }
                    this.setState({
                      isSubmitLoading: false,
                    });
                    history.push(routePaths.VIEW_BALANCE);
                  },

                  (error) => {
                    if (
                      error.response &&
                      error.response.data &&
                      error.response.status !== 500
                    ) {
                      this.setState({
                        error: true,
                        msg: error.response.data.errors[0].message,
                        isSubmitLoading: false,
                      });
                    } else {
                      this.setState({
                        error: true,
                        msg: labels.QUICK_PAY_ERROR,
                        isSubmitLoading: false,
                      });
                    }
                  }
                );
              }
            } else {
              this.setState({
                error: true,
                msg: labels.QUICK_PAY_ERROR_MESSAGE,
                isSubmitLoading: false,
              });
            }
          }
        }
      }
    }
  }
  componentDidUpdate() {
    if(document.getElementById("mobileNumber_Id")){
    if (this.state.mobileError === true) {
      document.getElementById("mobileNumber_Id").style.borderColor = "red";
    } else {
      document.getElementById("mobileNumber_Id").style.borderColor =
        "lightgrey";
    }
    }
  }
  handleOnChangePhone(value) {
    if (value) {
      this.setState({
        mobileNumber: value,
      });
      this.setState({
        error: false,
      });
      if (isValidPhoneNumber(value) === true) {
        let phoneNumber = parsePhoneNumber(value);
        if (phoneNumber) {
          let withoutExtMobile = phoneNumber.nationalNumber;

          if (withoutExtMobile) {
            this.setState({
              mobileError: false,
            });
          } else {
            this.setState({
              mobileError: true,
            });
          }
        }
      } else {
        this.setState({
          mobileError: true,
        });
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      error: false,
    });
    if (name === "connectionId") {
      if (this.props.billpay) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.setState({
            [name]: value,
          });
        }
      } else {
        const re = /^[A-Za-z0-9-]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          this.setState({
            [name]: value.toUpperCase(),
          });
        }
      }
    } else if (name === "mobileNumber") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [name]: value,
        });
      }
    } else if (name === "email") {
      const re = email_regex;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  connectionIdBlur() {
    const { connectionId } = this.state;
    if (connectionId) {
      this.setState({
        connectionIdError: false,
      });
      if (!this.props.billpay) {
        if (store.getState().parametersWidgets.ParameterLookup) {
          var connectionRegex = store.getState().parametersWidgets
            .ParameterLookup.CONNECTION_ID_REGEX;
          var connectionRegEx = new RegExp(connectionRegex);
          var test = connectionRegEx.test(connectionId);
          if (test) {
            this.setState({ connectionIdError: false });
          } else {
            this.setState({ connectionIdError: true });
          }
        }
      } else {
        if (connectionId.length === billId_length) {
          this.setState({ connectionIdError: false });
        } else {
          this.setState({ connectionIdError: true });
        }
      }
    } else {
      this.setState({
        connectionIdError: true,
      });
    }
  }

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  billingAPICall(billId) {
    this.setState({
      isSubmitLoading: true,
    });
    localStorage.setItem("billpay", true);
    usageService.getBillDetailsQPay(billId).then(
      (billIdRes) => {
        localStorage.setItem("landing",JSON.stringify(billIdRes.data.getBillDetailsQPay));
        localStorage.removeItem("email");
        localStorage.removeItem("mobileNo")
        this.props.getConnectionDetails(
          billIdRes.data.getBillDetailsQPay,
          "",
          ""
        );
        history.push(routePaths.VIEW_BALANCE);

        this.setState({
          isSubmitLoading: false,
        });
      },

      (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.status !== 500
        ) {
          this.props.redirection();
          this.setState({
            error: true,
            msg: error.response.data.errors[0].message,
            isSubmitLoading: false,
            directRedirection: true,
          });
        } else {
          this.setState({
            error: true,
            msg: labels.BILL_ERROR_MESSAGE,
            isSubmitLoading: false,
            directRedirection: true,
          });
          this.props.redirection();
        }
      }
    );
  }

  handlesubmitClick(e) {
    e.preventDefault();
    const {
      emailId,
      connectionId,
      connectionIdError,
      mobileError,
      emailError,
      mobileNumber,
    } = this.state;
    let notGo = "false";
    this.connectionIdBlur();
    if (connectionId) {
      if (!connectionIdError) {
        if (this.props.billpay) {
          //billpay APi
          this.billingAPICall(this.state.connectionId);
        } else {
          if (mobileNumber || emailId) {
            if (mobileNumber) {
              if(this.state.mobileError===true){
                notGo="true"
              }
            }
            if (emailId) {
              this.setState({
                error: false,
              });

              var emailRegEx = new RegExp(email_regex);
              var test = emailRegEx.test(emailId);
              if (test) {
                this.setState({ emailError: false });
              } else {
                notGo = "true";
                this.setState({ emailError: true });
              }
            }
            if (notGo !== "true") {
                  this.setState({
                    isSubmitLoading: true,
                  });
                  localStorage.setItem("billpay", false);
                  var exdate=new Date();
                  exdate.setDate(exdate.getDate() + (45*24*60*60*1000));
                  let expires = "expires="+ exdate.toUTCString();
                  document.cookie = this.state.connectionId ? "connectionId=" + this.state.connectionId + ";" + expires + ";path=/" :"";
                  document.cookie = emailId ?  "emailId=" + emailId + ";" + expires + ";path=/":"";
                  document.cookie = mobileNumber ?  "mobileNumber=" + mobileNumber + ";" + expires + ";path=/" : "";
                  usageService.getBalance(this.state.connectionId).then(
                    (res) => {
                      this.props.getConnectionDetails(
                        res.data.postBalanceDetails,
                        mobileNumber,
                        emailId
                      );
                      localStorage.setItem("landing",JSON.stringify(res.data.postBalanceDetails));
                      localStorage.setItem("email",emailId ? emailId : "");
                      localStorage.setItem("mobileNo",mobileNumber)
  
                      this.setState({
                        isSubmitLoading: false,
                      });
                      history.push(routePaths.VIEW_BALANCE);
                    },

                    (error) => {
                      if (
                        error.response &&
                        error.response.data &&
                        error.response.status !== 500
                      ) {
                        this.setState({
                          error: true,
                          msg: error.response.data.errors[0].message,
                          isSubmitLoading: false,
                        });
                      } else {
                        this.setState({
                          error: true,
                          msg: labels.QUICK_PAY_ERROR,
                          isSubmitLoading: false,
                        });
                      }
                    }
                  );
            }
          } else {
            this.setState({
              error: true,
              msg: labels.QUICK_PAY_ERROR_MESSAGE,
              isSubmitLoading: false,
            });
          }
        }
      }
    }
  }
  emailBlur() {
    if (store.getState().parametersWidgets.ParameterLookup) {
      if (this.state.emailId) {
        let email = this.state.emailId?.toLowerCase();
        var emailData = store.getState().parametersWidgets.ParameterLookup
          .EMAIL_REGEX;
        var emailRegEx = new RegExp(emailData);
        var test = emailRegEx.test(email);
        if (test) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
      } 
    }
  }
  updateState = (object) => {
    this.setState({ ...object })
  }
  render() {
    let { connectionId, mobileNumber, emailId } = this.state;
    let quaternaryColorText = `margin-top-40 ${this.props.baseStyle.quaternaryColor}`;
    let billIdInUrl = new URLSearchParams(window.location.search).get("bill-id");

    return !billIdInUrl ? (
      <React.Fragment>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} sm={7}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography color="primary" component="h1" variant="h6">
                  <b>
                    {this.props.billpay
                      ? labels.BILL_PAY_VIEW_INVOICE_TITLE
                      : labels.QUICK_PAY_VIEW_ACCOUNT_BALANCE_TITLE}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Hidden smUp>
                  <HelpIcon
                    className={this.props.baseStyle.linkColor}
                    onClick={this.handleClickOpen}
                  />
                </Hidden>
              </Grid>
            </Grid>
            <br />
            <form onSubmit={this.handlesubmitClick}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12} sm={9} lg={6}>
                  <ConnectionFinder
                    parentSetState={this.updateState}
                  ></ConnectionFinder>
                  <Typography
                    align="left"
                    className={
                      this.state.connectionIdError
                        ? this.props.baseStyle.errorColor
                        : null
                    }
                  >
                    <b>
                      {this.props.billpay
                        ? labels.BILL_PAY_BILL_ID
                        : labels.QUICK_PAY_CONNECTION_ID}
                    </b>
                  </Typography>
                  <ConnectionOutlinedTextFiled
                    fullWidth
                    // autoFocus
                    variant="outlined"
                    id="connectionId"
                    inputProps={{
                      maxLength: this.props.billpay
                        ? billId_length
                        : connectionId_length,
                    }}
                    name="connectionId"
                    value={connectionId}
                    onChange={this.handleChange}
                    onBlur={this.connectionIdBlur}
                    placeholder={
                      this.props.billpay
                        ? labels.BILL_PAY_BILL_ID_PLACEHOLDER
                        : labels.QUICK_PAY_CONNECTION_ID_PLACEHOLDER
                    }
                    error={this.state.connectionIdError}
                    helperText={
                      this.state.connectionIdError
                        ? this.props.billpay
                          ? labels.BILL_PAY_BILL_ID_NOT_VALID
                          : labels.QUICK_PAY_CONNECTION_ID_NOT_VALID
                        : null
                    }
                  />
                </Grid>
                {!this.props.billpay && (
                  <React.Fragment>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        align="left"
                        justify="space-between"
                        className="margin-top-20"
                      >
                        <Grid item>
                          <Typography align="center" color="primary">
                            <b>{labels.QUICK_PAY_ACKNOWLEDGEMENT_TITLE}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <React.Fragment>
                        <Typography
                          align="left"
                          className={
                            this.state.mobileError
                              ? this.props.baseStyle.errorColor
                              : null
                          }
                        >
                          {labels.SIGNUP_REGISTERED_CELLNUMBER}
                        </Typography>
                        <PhoneInput
                          variant="outlined"
                          fullWidth
                          flags={flags}
                          id="mobileNumber_Id"
                          name="mobileNumber"
                          value={mobileNumber}
                          autoComplete="off"
                          placeholder={labels.SIGNUP_CELLNUMBER_PLACEHOLDER}
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="QA"
                          onChange={this.handleOnChangePhone}
                          limitMaxLength={true}
                        />
                        <Typography
                          variant="caption"
                          color="error"
                          className="marginleftError"
                        >
                          {this.state.mobileError === true
                            ? labels.SIGNUP_CELLNUMBER_NOT_VALID
                            : null}
                        </Typography>
                        
                      </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={6}>
                      <React.Fragment>
                        <Typography
                          align="left"
                          className={
                            this.state.emailError
                              ? this.props.baseStyle.errorColor
                              : null
                          }
                        >
                          {labels.SIGNUP_REGISTERED_EMAIL}
                        </Typography>
                        <OutlinedTextFiled
                          fullWidth
                          variant="outlined"
                          id="emailId_id"
                          name="emailId"
                          value={emailId?.toLocaleLowerCase()}
                          onChange={this.handleChange}
                          onBlur={this.emailBlur}
                          autoComplete="off"
                          placeholder={labels.QUICK_PAY_EMAIL_PLACEHOLDER}
                          error={this.state.emailError}
                          helperText={
                            this.state.emailError === true
                              ? labels.ERROR_EMAIL_VALIDATION
                              : null
                          }
                        />
                      </React.Fragment>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <br />

              {this.state.error === true && (
                <Alert severity="error" className="margin-right-30">
                  {this.state.msg}
                </Alert>
              )}
              <br />
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={2}
                direction="row"
              >
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={
                      this.state.isSubmitLoading ? null : this.handlesubmitClick
                    }
                    className={
                      this.state.isSubmitLoading
                        ? this.props.authStyle.loadingCursor
                        : null
                    }
                  >
                    {labels.BUTTON_SUBMIT}&nbsp;
                    {this.state.isSubmitLoading ? (
                      <CircularProgress
                        size={20}
                        color="inherit"
                        className={this.props.baseStyle.whiteColor}
                      />
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Typography
              variant="body1"
              className={quaternaryColorText}
              dangerouslySetInnerHTML={{
                __html: this.props.lastNote,
              }}
            ></Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item sm={5}>
              <Card className={this.props.baseStyle.lightblueColorBackground}>
                {this.props.data ? (
                  <AboutAccount
                    baseStyle={this.props.baseStyle}
                    data={this.props.data}
                    billId={this.state.billId}
                    billpay={this.props.billpay}
                  />
                ) : (
                  <Skeleton variant="text" />
                )}
              </Card>
            </Grid>
          </Hidden>
        </Grid>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.openDialog}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.handleClose}
            className={this.props.baseStyle.lightblueColorBackground}
          >
            <Typography color="primary" variant="h5" component="h2">
              <b>{labels.QUICK_PAY_ABOUT_YOUR_ACCOUNT_TITLE}</b>
            </Typography>
          </DialogTitle>
          <MuiDialogContent
            className={this.props.baseStyle.lightblueColorBackground}
          >
            {this.props.data ? (
              <AboutAccount
                baseStyle={this.props.baseStyle}
                data={this.props.data}
                billId={this.state.billId}
                billpay={this.props.props}
              />
            ) : (
              <Skeleton variant="text" />
            )}
          </MuiDialogContent>
        </Dialog>
      </React.Fragment>
    ) : null;
  }
}

const actionCreators = {
  getConnectionDetails: appActions.getConnectionDetails,
};

const connectedApp = connect(null, actionCreators)(QuickPayLanding);
export { connectedApp as QuickPayLanding };

import React from "react";
import "../auth/auth.css";
import "../app/base-style.css";
import { store } from "../../helpers";
import { connect } from "react-redux";
import { pathActions } from "../../actions";
import {
  Typography,
  Grid,
  CardContent,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Link,
} from "@material-ui/core";
import "./quick-pay.css";
import { usageService } from "../../services/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { routePaths } from "../config/route-paths";
import { Alert } from "@material-ui/lab";
import { adminService, commonService } from "../../services/index";
import Checkbox from "@material-ui/core/Checkbox";
import TransactionRequest from "./debit/transaction";
import HostedCheckout from "./credit-card/credit-card-hosted-checkout";
import { config } from "../../enviroment";
import Cookies from "universal-cookie";
import { bearerTokenService } from "../../services/bearer.service";
import { CircularDialog } from "./dialog-style";
import { history } from "../../helpers/history";
import qcApplogo from "../../assests/qc-default-logo.png";
import CyberSourceTransactionRequest from "./credit-card/cyberSourceTransaction";
import SkipCashTransactionRequest from "./debit/skipCashTransactionRequest";

let labels = {};
let minAmount = "";
let maxAmount = "";
let currency = "";
let paymentType = "";
let paymentTypeQPAY = "";
let paymentTypeSkipCash = "";
let array = [];
let isSkipCashDebitCard = false;
let isSkipCashCreditCard = false;
let isCyberSourceGateway = false;
let paymentTypeMigs="";
store.subscribe(() => {
  if (store.getState().labels.data) {
    labels = store.getState().labels.data.getLabels.USER_MANAGEMENT;
  }
  if (store.getState().parametersWidgets.ParameterLookup) {
    minAmount =
      store.getState().parametersWidgets.ParameterLookup.MINIMUM_AMOUNT_VALUE;
    minAmount = Number(minAmount);
    maxAmount =
      store.getState().parametersWidgets.ParameterLookup.MAXIMUM_AMOUNT_VALUE;
    maxAmount = Number(maxAmount);
    currency = store.getState().parametersWidgets.ParameterLookup.CURRENCY_CODE;
    paymentType = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[2];
    paymentTypeMigs = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[1];
    paymentTypeQPAY = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[0];
    paymentTypeSkipCash = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[3];
    console.log(
      "store.getState().parametersWidgets.ParameterLookup.CREDIT_CARD_GATEWAY_CODE",
      store.getState().parametersWidgets.ParameterLookup
        .CREDIT_CARD_GATEWAY_CODE
    );
    console.log(
      "store.getState().parametersWidgets.ParameterLookup.DEBIT_CARD_GATEWAY_CODE",
      store.getState().parametersWidgets.ParameterLookup.DEBIT_CARD_GATEWAY_CODE
    );
    if (
      store.getState().parametersWidgets.ParameterLookup
        .CREDIT_CARD_GATEWAY_CODE === "SkipCash_Gateway"
    ) {
      isSkipCashCreditCard = true;
    }
    if (
      store.getState().parametersWidgets.ParameterLookup
        .CREDIT_CARD_GATEWAY_CODE === "CyberSource_Gateway"
    ) {
      isCyberSourceGateway = true;
    }
    if (
      store.getState().parametersWidgets.ParameterLookup
        .DEBIT_CARD_GATEWAY_CODE === "SkipCash_Gateway"
    ) {
      isSkipCashDebitCard = true;
    }
  }
  console.log("isSkipCashCreditCard", isSkipCashCreditCard);
  console.log("isSkipCashDebitCard", isSkipCashDebitCard);
});

class BalancePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billIdRes: {},
      connectionId: "",
      openDialog: false,
      data: "",
      checkedA: false,
      labelSubTitle: "",
      lastNote: "",
      alertMessage: "",
      emailId: "",
      billId: props.billpay,
      title:
        props.billpay === "true"
          ? labels.BILL_PAY_VIEW_INVOICE_AMOUNT
          : labels.QUICK_PAY_VIEW_ACCOUNT_BALANCE_TITLE,
      routeBack: "",
      landingResponse: "",
      mobileNo: "",
      sessionId: "",
      currencycode: "",
      merchant: "",
      merchantName: "",
      orderInfo: "",
      balance: "",
      id: "",
      progress: 0,
      name: "",
      status: "",
      appLogo: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adminCall = this.adminCall.bind(this);
  }

  componentDidMount() {
    if (store.getState().appLogo) {
      this.setState({
        appLogo: store.getState().appLogo.assetPath,
      });
    }
    if (store.getState().connectionId?.connectionId) {
      let landingResponse = store.getState().connectionId.connectionId;
      let name = "";
      if ((landingResponse.personOrBusiness = "P")) {
        if (landingResponse.title) {
          let title = landingResponse.title;
          let firstName = landingResponse.firstName;
          let lastName = landingResponse.lastName;
          let middleName = landingResponse.middleName;
          if (middleName) {
            name = title + ". " + firstName + " " + middleName + "" + lastName;
          } else {
            name = title + ". " + firstName + " " + lastName;
          }
        } else {
          name = landingResponse.customerName;
        }
      } else {
        name = landingResponse.customerName;
      }
      this.setState({
        landingResponse,
        name,
        emailId: store.getState().connectionId.emailId,
        mobileNo: store.getState().connectionId.mobileNo,
        status: landingResponse.status,
      });
      if (this.props.billpay === "true") {
        let routeBack = routePaths.BILL_PAY;
        this.setState({
          routeBack,
          balance: landingResponse.billDetails.totalAmountDue,
          id: landingResponse.billDetails.billNumber,
          name,
        });
      } else {
        let routeBack = routePaths.QUICK_PAY;
        this.setState({
          routeBack,
          balance: landingResponse.outstandingBalance,
          id: landingResponse.connectionId,
          name,
        });
      }
    } else if (store.getState().connectionId && this.props.billpay === "true") {
      let landingResponse = store.getState().connectionId.connectionId;
      usageService
        .getBillDetailsQPay(landingResponse.billDetails.billNumber)
        .then((billIdRes) => {
          this.setState({ billIdRes: billIdRes.data.getBillDetailsQPay });
        });
    } else {
      if (localStorage.getItem("landing")) {
        let landingResponse = JSON.parse(localStorage.getItem("landing"));
        let name = "";
        if ((landingResponse.personOrBusiness = "P")) {
          if (landingResponse.title) {
            let title = landingResponse.title;
            let firstName = landingResponse.firstName;
            let lastName = landingResponse.lastName;
            let middleName = landingResponse.middleName;
            if (middleName) {
              name =
                title + ". " + firstName + " " + middleName + "" + lastName;
            } else {
              name = title + ". " + firstName + " " + lastName;
            }
          } else {
            name = landingResponse.customerName;
          }
        } else {
          name = landingResponse.customerName;
        }
        this.setState({
          landingResponse,
          name,
          emailId: localStorage.getItem("email"),
          mobileNo: localStorage.getItem("mobileNo"),
          status: landingResponse.status,
        });
        if (this.props.billpay === "true") {
          let routeBack = routePaths.BILL_PAY;
          this.setState({
            routeBack,
            balance: landingResponse.billDetails.totalAmountDue,
            id: landingResponse.billDetails.billNumber,
          });
        } else {
          let routeBack = routePaths.QUICK_PAY;
          this.setState({
            routeBack,
            balance: landingResponse.outstandingBalance,
            id: landingResponse.connectionId,
          });
        }
      } else {
        history.length >= 1
          ? history.goBack()
          : history.push(routePaths.BILL_PAY);
      }
    }
    let authbear = localStorage.getItem("authbearer");

    if (authbear) {
      this.adminCall();
    } else {
      bearerTokenService
        .getBearerToken()
        .then((successBearer) => {
          this.adminCall();
        })
        .catch((error) => {});
    }
  }

  adminCall() {
    adminService.getContentData("en", "INITIATE_PAYMENT").then((res) => {
      this.setState({
        data: res.data.getContents.INITIATE_PAYMENT,
      });
      res.data.getContents.INITIATE_PAYMENT.forEach((item) => {
        if (item.contentCode === "INITIATE_PAYMENT_ALERT") {
          this.setState({
            alertMessage: item.answer,
          });
        }
      });
    });
  }

  debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      checkedA: event.target.checked,
    });
  };

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  submitBillPay = () => {
    store.getState().snackbar = true;
    this.handleClickOpen();
    array.push({
      billId: this.state.id,
      Amount: this.state.balance,
      accountId: this.state.landingResponse.accountDetails.accountId,
      userName: this.state.landingResponse.accountDetails.name,
      connectionId: this.state.landingResponse.accountDetails.connectionId,
    });
    localStorage.removeItem("BillpayDetails");
    localStorage.setItem("BillpayDetails", JSON.stringify(array));
    localStorage.removeItem("email");
    localStorage.setItem(
      "email",
      this.state.landingResponse.accountDetails.email
    );
    localStorage.removeItem("billId");
    localStorage.setItem("billId", this.state.id);

    localStorage.removeItem("mobileNo");
    localStorage.setItem(
      "mobileNo",
      this.state.landingResponse.accountDetails.phone
    );
    localStorage.removeItem("paymentType");
    localStorage.setItem("paymentType", paymentType);
    localStorage.setItem("paymentProcess", "Y");
    usageService
      .prePaymentProcessingBill(
        this.state.id,
        this.state.balance,
        paymentType,
        this.state.landingResponse.accountDetails.connectionId
      )
      .then((res) => {
        localStorage.removeItem("paymentProcess");
        this.setState({
          sessionId: res.vpc_MerchTxnRef,
          orderId: res.vpc_OrderId,
          currencycode: res.vpc_Currency,
          merchant: res.vpc_Merchant,
          merchantName: res.vpc_Merchant_Name,
          orderInfo: this.state.landingResponse.accountDetails.connectionId,
          emailId: this.state.landingResponse.accountDetails.email,
          mobileNo: this.state.landingResponse.accountDetails.phone,
        });
        localStorage.removeItem("orderId");
        localStorage.setItem("orderId", res.vpc_OrderId);

        HostedCheckout(
          this.state.orderId,
          this.state.sessionId,
          this.state.currencycode,
          this.state.merchant,
          this.state.merchantName,
          this.state.orderInfo,
          this.state.balance,
          this.state.mobileNo.toString(),
          this.state.emailId,
          "QAT",
          config.urls.ASSEST_URL_ENDPOINT + "/qc-default-logo.png"
        );
      })
      .catch((error) => {
        localStorage.removeItem("paymentProcess");
        this.setState({
          noData: true,
        });
      });
  };

  submit = () => {
    store.getState().snackbar = true;
    this.handleClickOpen();
    array.push({
      ConnectionId: this.state.id,
      Amount: this.state.balance,
    });
    let check = commonService.encodeString(
      JSON.stringify(this.state.landingResponse)
    );
    localStorage.removeItem("Details");
    localStorage.setItem("Details", JSON.stringify(array));
    localStorage.removeItem("email");
    localStorage.setItem("email", this.state.emailId);
    localStorage.removeItem("customerName");
    localStorage.setItem("customerName", this.state.name);
    localStorage.removeItem("connectionId");
    localStorage.setItem("connectionId", check);
    localStorage.removeItem("mobileNo");
    localStorage.setItem("mobileNo", this.state.mobileNo);
    localStorage.setItem("paymentProcess", "Y");
    usageService
      .prePaymentProcessing(this.state.id, this.state.balance, isCyberSourceGateway ? paymentType: paymentTypeMigs)
      .then((res) => {
        localStorage.removeItem("paymentProcess");
        localStorage.removeItem("orderId");
        localStorage.setItem(
          "orderId",
          res.data.prePaymentProcessing.vpc_OrderId
        );
        this.setState({
          sessionId: res.data.prePaymentProcessing.vpc_MerchTxnRef,
          orderId: res.data.prePaymentProcessing.vpc_OrderId,
          currencycode: res.data.prePaymentProcessing.vpc_Currency,
          merchant: res.data.prePaymentProcessing.vpc_Merchant,
          merchantName: res.data.prePaymentProcessing.vpc_Merchant_Name,
          orderInfo: res.data.prePaymentProcessing.vpc_OrderInfo,
        });

        HostedCheckout(
          this.state.orderId,
          this.state.sessionId,
          this.state.currencycode,
          this.state.merchant,
          this.state.merchantName,
          this.state.id,
          this.state.landingResponse.outstandingBalance,
          this.state.mobileNo.toString(),
          this.state.emailId,
          "QAT",
          config.urls.ASSEST_URL_ENDPOINT + "/qc-default-logo.png"
        );
      })
      .catch((error) => {
        localStorage.removeItem("paymentProcess");
        this.setState({
          noData: true,
        });
      });
  };

  render() {
    let quaternaryColorText = `margin-top-10  ${this.props.baseStyle.quaternaryColor}`;

    return (
      <React.Fragment>
        {this.state.landingResponse && (
          <React.Fragment>
            <Grid container justify="space-around" alignItems="center">
              <Grid item sm={12} md={8}>
                <Grid container direction="column" justify="center">
                  <Grid item>
                    <Typography
                      color="primary"
                      variant="h6"
                      component="h2"
                      align="center"
                    >
                      <b>
                        {this.props.billpay === "true"
                          ? labels.BILL_PAY_VIEW_INVOICE_AMOUNT
                          : labels.QUICK_PAY_VIEW_ACCOUNT_BALANCE_TITLE}
                      </b>
                    </Typography>
                    <br />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                    >
                      <Grid item xs={5}>
                        <Typography align="right">
                          <b>
                            {this.props.billpay === "true"
                              ? labels.BILL_PAY_BILL_ID
                              : labels.QUICK_PAY_CONNECTION_ID}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography align="left">{this.state.id}</Typography>
                      </Grid>
                    </Grid>
                    {this.state.status && this.props.billpay !== "true" && (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="center"
                      >
                        <Grid item xs={5}>
                          <Typography align="right">
                            <b>
                              {labels.QUICKPAY_STATUS
                                ? labels.QUICKPAY_STATUS
                                : "Status"}
                            </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            align="left"
                            className={
                              this.state.status === "Active"
                                ? this.props.baseStyle.greenColor
                                : this.state.status === "Reactivated"
                                ? this.props.baseStyle.greenColor
                                : this.state.status === "Stopped"
                                ? this.props.baseStyle.redcolor
                                : this.state.status === "Closed"
                                ? this.props.baseStyle.redcolor
                                : this.state.status === "Canceled"
                                ? this.props.baseStyle.redcolor
                                : this.props.baseStyle.quaternaryColor
                            }
                          >
                            {this.state.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                    >
                      <Grid item xs={5}>
                        <Typography align="right">
                          <b>
                            {this.props.billpay == "true"
                              ? "Total Amount"
                              : labels.BALANCE_PAGE_AMOUNT_DUE}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          align="left"
                          className={
                            this.state.balance > 0
                              ? this.props.baseStyle.redcolor
                              : this.props.baseStyle.greenColor
                          }
                        >
                          {currency}{" "}
                          {Number(
                            parseFloat(this.state.balance).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    {this.state.billIdRes && this.props.billpay == "true" ? (
                      <React.Fragment>
                        {this.state.billIdRes &&
                        this.state.billIdRes.summaryOfCharges &&
                        this.state.billIdRes.summaryOfCharges[0].name ? (
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justify="center"
                          >
                            <Grid item xs={5}>
                              <Typography align="right">
                                <b>{labels.BALANCE_PAGE_BILLPAY_NAME} </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="left">
                                {this.state.billIdRes &&
                                this.state.billIdRes.summaryOfCharges
                                  ? this.state.billIdRes.summaryOfCharges[0]
                                      .name
                                  : null}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}

                        {this.state.billIdRes &&
                        this.state.billIdRes.summaryOfCharges &&
                        this.state.billIdRes.summaryOfCharges[0].saInfo ? (
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justify="center"
                          >
                            <Grid item xs={5}>
                              <Typography align="right">
                                <b>{labels.BALANCE_PAGE_BILLPAY_SAINFO}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="left">
                                {this.state.billIdRes &&
                                this.state.billIdRes.summaryOfCharges
                                  ? this.state.billIdRes.summaryOfCharges[0]
                                      .saInfo
                                  : null}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}

                        {this.state.billIdRes &&
                        this.state.billIdRes.summaryOfCharges &&
                        this.state.billIdRes.accountDetails.phone ? (
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justify="center"
                          >
                            <Grid item xs={5}>
                              <Typography align="right">
                                <b>
                                  {labels.BALANCE_PAGE_BILLPAY_MOBILE_NUMBER}
                                </b>
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="left">
                                {this.state.billIdRes &&
                                this.state.billIdRes.summaryOfCharges
                                  ? this.state.billIdRes.accountDetails.phone
                                  : null}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                    {this.props.billpay !== "true" ? (
                      <React.Fragment>
                        {/* <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                     >
                      <Grid item xs={5}>
                        <Typography align="right">
                          <b>{labels.BALANCE_CUSTOMER_ID}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography align="left">
                          {this.state.landingResponse.customerId}
                        </Typography>
                      </Grid>
                    </Grid> */}
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={5}>
                            <Typography align="right">
                              <b>{labels.BALANCE_CUSTOMER_NAME}</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography align="left">
                              {this.state.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ) : null}

                    <Grid
                      container
                      spacing={1}
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      {this.state.balance && (
                        <React.Fragment>
                          {this.state.balance < minAmount ||
                          this.state.balance > maxAmount ? (
                            <Grid
                              item
                              alignItems="center"
                              className="margin-top-30"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={true}
                                    value={this.checkedA}
                                    align="center"
                                  />
                                }
                                label={labels.BALANCE_AGREE_PAYMENT_TERMS}
                                align="center"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.checkedA}
                                    onChange={this.handleChange}
                                    name="checkedA"
                                    color="primary"
                                    align="center"
                                  />
                                }
                                label={labels.BALANCE_AGREE_PAYMENT_TERMS}
                                align="center"
                              />
                            </Grid>
                          )}
                          <Typography color="primary" align="center">
                            <b>{labels.BALANCE_PAGE_PAY_NOW}</b>
                          </Typography>
                          <br />
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            align="center"
                            justify="center"
                          >
                            {/* <Grid item>
                              {this.props.billpay === "true" ? (
                                <Button
                                  variant="contained"
                                  size="medium"
                                  disabled={!this.state.checkedA}
                                  className={
                                    this.state.checkedA
                                      ? this.props.baseStyle.accentButton
                                      : null
                                  }
                                  onClick={
                                    this.state.balance < minAmount ||
                                    this.state.balance > maxAmount
                                      ? null
                                      : this.debounce(this.submitBillPay, 2000)
                                  }
                                >
                                  {labels.BALANCE_PAGE_CREDIT_CARD}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="medium"
                                  disabled={!this.state.checkedA}
                                  className={
                                    this.state.checkedA
                                      ? this.props.baseStyle.accentButton
                                      : null
                                  }
                                  onClick={
                                    this.state.balance < minAmount ||
                                    this.state.balance > maxAmount
                                      ? null
                                      : this.debounce(this.submit, 2000)
                                  }
                                >
                                  {labels.BALANCE_PAGE_CREDIT_CARD}
                                </Button>
                              )}
                            </Grid> */}
                            <Grid item>
                              {isSkipCashCreditCard ? (
                                <SkipCashTransactionRequest
                                  checkedA={this.state.checkedA}
                                  label={labels}
                                  billpay={this.props.billpay}
                                  connectionId={this.state.id}
                                  amount={this.state.balance}
                                  paymentType={paymentTypeSkipCash}
                                  landingResponse={this.state.landingResponse}
                                  emailId={this.state.emailId}
                                  mobileNo={this.state.mobileNo}
                                  cardTitle={labels.BALANCE_PAGE_CREDIT_CARD}
                                  className={this.props.baseStyle.accentButton}
                                />
                              ) : isCyberSourceGateway? (
                                <CyberSourceTransactionRequest
                                  checkedA={this.state.checkedA}
                                  label={labels}
                                  accentButton={
                                    this.props.baseStyle.accentButton
                                  }
                                  billpay={this.props.billpay}
                                  connectionId={this.state.id}
                                  amount={this.state.balance}
                                  paymentType={paymentType}
                                  landingResponse={this.state.landingResponse}
                                  emailId={this.state.emailId}
                                  mobileNo={this.state.mobileNo}
                                />
                              ):
                              (
                                <Grid item>
                                {this.props.billpay === "true" ? (
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    disabled={!this.state.checkedA}
                                    className={
                                      this.state.checkedA
                                        ? this.props.baseStyle.accentButton
                                        : null
                                    }
                                    onClick={
                                      this.state.balance < minAmount ||
                                      this.state.balance > maxAmount
                                        ? null
                                        : this.debounce(this.submitBillPay, 2000)
                                    }
                                  >
                                    {labels.BALANCE_PAGE_CREDIT_CARD}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    disabled={!this.state.checkedA}
                                    className={
                                      this.state.checkedA
                                        ? this.props.baseStyle.accentButton
                                        : null
                                    }
                                    onClick={
                                      this.state.balance < minAmount ||
                                      this.state.balance > maxAmount
                                        ? null
                                        : this.debounce(this.submit, 2000)
                                    }
                                  >
                                    {labels.BALANCE_PAGE_CREDIT_CARD}
                                  </Button>
                                )}
                              </Grid> 
                            )
                              }
                            </Grid>
                            {/*
                            <Grid item>
                              <TransactionRequest
                                checkedA={this.state.checkedA}
                                label={labels}
                                accentButton={
                                  this.props.baseStyle.accentButtonDebit
                                }
                                billpay={this.props.billpay}
                                connectionId={this.state.id}
                                amount={this.state.balance}
                                paymentTypeQPAY={paymentTypeQPAY}
                                landingResponse={this.state.landingResponse}
                                emailId={this.state.emailId}
                                mobileNo={this.state.mobileNo}
                              />
                            </Grid>{" "}
                            */}
                            <Grid item>
                              {isSkipCashDebitCard ? (
                                <SkipCashTransactionRequest
                                  checkedA={this.state.checkedA}
                                  label={labels}
                                  accentButton={
                                    this.props.baseStyle.accentButtonDebit
                                  }
                                  billpay={this.props.billpay}
                                  connectionId={this.state.id}
                                  amount={this.state.balance}
                                  paymentType={paymentTypeSkipCash}
                                  landingResponse={this.state.landingResponse}
                                  emailId={this.state.emailId}
                                  mobileNo={this.state.mobileNo}
                                  cardTitle={labels.BALANCE_PAGE_DEBIT_CARD}
                                  className={
                                    this.props.baseStyle.accentButtonDebit
                                  }
                                />
                              ) : (
                                <>
                                  {" "}
                                  <TransactionRequest
                                    checkedA={this.state.checkedA}
                                    label={labels}
                                    accentButton={
                                      this.props.baseStyle.accentButtonDebit
                                    }
                                    billpay={this.props.billpay}
                                    connectionId={this.state.id}
                                    amount={this.state.balance}
                                    paymentTypeQPAY={paymentTypeQPAY}
                                    landingResponse={this.state.landingResponse}
                                    emailId={this.state.emailId}
                                    mobileNo={this.state.mobileNo}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <br />
                          {this.state.balance < minAmount ||
                          this.state.balance > maxAmount ? (
                            <Alert severity="warning" className="margin-top-20">
                              {this.state.alertMessage ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.alertMessage,
                                  }}
                                />
                              ) : null}
                            </Alert>
                          ) : null}

                          <br />
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  variant="body1"
                  align="center"
                  className={quaternaryColorText}
                  dangerouslySetInnerHTML={{
                    __html: this.props.lastNote,
                  }}
                />
              </Grid>
            </Grid>
            <hr className="margin-top-20" />
            <Link href={this.state.routeBack}>
              <Typography
                color="primary"
                align="center"
                className="margin-top-30"
              >
                &#10094; {labels.BALANCE_PAGE_BACK}
              </Typography>{" "}
            </Link>
            <CircularDialog
              onClose={this.handleClose}
              aria-labelledby="simple-dialog-title"
              open={this.state.openDialog}
            >
              <DialogTitle id="simple-dialog-title">
                <Typography color="primary" variant="h5">
                  <b>{labels.LOADING_DIALOG_TITLE}</b>
                </Typography>
              </DialogTitle>
              <DialogContent align="center">
                <div className="margin-top-20">
                  <CircularProgress color="primary" size={60} />
                </div>
              </DialogContent>
              <DialogActions>
                <Typography className="padding-10">
                  {labels.LOADING_DIALOG_SUBTITLE}
                </Typography>{" "}
              </DialogActions>
            </CircularDialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const actionCreators = {
  getLocationPath: pathActions.getLocationPath,
};
const connectedBalance = connect(null, actionCreators)(BalancePage);
export { connectedBalance as BalancePage };
